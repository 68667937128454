import React from "react";
import logo from './img/logo.png'
import {Link} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import {useLanguage} from "./context/language";

function Navbar(props) {
    const {language, setLanguage} = useLanguage()

    const ruButtonStyle = (language === "ru") ? "btn btn-dark btn-sm me-2" : "btn btn-outline-dark btn-sm me-2"
    const roButtonStyle = (language === "ro") ? "btn btn-dark btn-sm me-2" : "btn btn-outline-dark btn-sm me-2"
    const enButtonStyle = (language === "en") ? "btn btn-dark btn-sm me-2" : "btn btn-outline-dark btn-sm me-2"

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-gradient">
            <div className="container">
                <div className="navbar-header">
                    <Link className="navbar-brand" to="/">
                        <img src={logo} alt="" height="30" className="d-inline-block align-top"/>
                    </Link>
                </div>
                <div className="d-flex">
                    <button className={enButtonStyle} type="button" onClick={() => setLanguage("en")}>English</button>
                    <button className={ruButtonStyle} type="button" onClick={() => setLanguage("ru")}>Русский</button>
                    <button className={roButtonStyle} type="button" onClick={() => setLanguage("ro")}>Romana</button>
                </div>
            </div>
        </nav>
    )
}

export default Navbar;