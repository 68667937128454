import {Link, useParams} from 'react-router-dom';
import {useLanguage} from "./context/language";
import voted from './img/voted-green.png'
import showme from './img/showmewhatyougot.jpg'

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import React, {useEffect, useState} from "react";
import axios from "axios";


function getOptionText(option, language) {
    const ro = {
        "1": "#1 Posibilul utilizator",
        "2": "#2 Nu am nimic împotrivă",
        "3": "#3 Sunt împotrivă"
    }
    const ru = {
        "1": "#1 Возможный пользователь",
        "2": "#2 Не против",
        "3": "#3 Против"
    }
    const parsedOption = parseInt(option);

    if (isNaN(parsedOption) || parsedOption < 1 || parsedOption > 3) {
        return (language === "ro") ? "Eu sunt hackerul mamei" : "Я у мамы хакер"
    } else {
        return language === "ro" ? ro[option] : ru[option]
    }
}

const textAddContact = {
    "ro": "Adaugă o modalitate de contact",
    "ru": "Добавить способ связаться"
}
const textAddReasons = {
    "ro": "Împărtășește-ți motivele",
    "ru": "Рассказать о причинах"
}

function PollResultsSuccess(props) {
    const {language} = useLanguage()
    const {guid, option} = useParams();
    const title = (language === "ro") ? "Vă mulțumim!" : "Спасибо!"

    const content =
        <div>
            <div>{(language === "ro") ? "Alegerea dvs. a fost înregistrată cu succes" : "Ваш выбор успешно зарегистрирован"}</div>
            <div><strong>{getOptionText(option, language)}</strong></div>
        </div>

    return (
        <>
                <img className="d-block mx-auto mb-4 mt-2" src={parseInt(option) == 3 ? showme : voted} alt=""
                     height="150"/>
                <h1 className="display-5 fw-bold">{title}</h1>
                <p className="fw-light mb-4">{content}</p>

                <div className="col-lg-6 mx-auto">
                    <div className="d-grid gap-2 d-sm-flex justify-content-sm-center mb-2">
                        <Link to={"/poll/v1/" + guid}>
                            <button type="button" className="btn btn-outline-danger btn-lg px-4 me-sm-3 w-100"><i
                                className="bi-pencil me-3"></i>{language === "ro" ? "Schimbă alegerea" : "Изменить выбор"}
                            </button>
                        </Link>
                        <Link to={"/poll/v1/" + guid + "/contacts"}>
                            <button type="button" className="btn btn-primary btn-lg px-4 me-sm-3 w-100"><i
                                className="bi-person-add me-3"></i>{((parseInt(option) === 3) ? textAddReasons : textAddContact)[language]}
                            </button>
                        </Link>
                    </div>
                    {(parseInt(option) !== 3) ? <div className="alert alert-secondary" role="alert">
                        <p className="text-left">{
                            language === "ro" ? "Colectăm date de contact în cazul în care va trebui să adunăm semnături în susținerea proiectului."
                                : "Мы собираем контактные данные на случай, если будем вынуждены собирать подписи в поддержку проекта."}</p>
                    </div> : <div/>}

                </div>
        </>
    )
}



function VoteInProgress(props) {
    const {language} = useLanguage()

    const text = {
        wait: {
            "ru": "Пожалуйста подождите",
            "ro": "Vă rugăm să așteptați"
        },
        processing: {
            "ru": "Мы обрабатываем ваш голос",
            "ro": "Votul dumneavoastră se procesează"
        }
    }

    return <div className="alert alert-info" role="alert">
        <h4 className="alert-heading">{text.wait[language]}</h4>
        <p>{text.processing[language]}</p>
    </div>
}

function PollResultsInternalError(props) {
    const {language} = useLanguage()

    const text = {
        wait: {
            "ru": "Произошла техническая ошибка",
            "ro": "A apărut o eroare tehnică."
        },
        processing: {
            "ru": "Пожалуйста, попробуйте перегрузить страницу. Если это не помогло, убедительная просьба попробовать чуть позже - ваш голос очень важен для нас и было бы досадно его потерять. ",
            "ro": "Vă rugăm să încercați să reîmprospătați pagina. Dacă asta nu ajută, vă rugăm să încercați mai târziu - votul dumneavoastră este foarte important pentru noi și ar fi păcat să-l pierdem. Mulțumim!"
        }
    }

    return <div className="alert alert-danger" role="alert">
        <h4 className="alert-heading">{text.wait[language]}</h4>
        <p>{text.processing[language]}</p>
        <hr/>

    </div>
}

function PollResults(props) {
    const {language} = useLanguage()
    const {guid, option} = useParams();

    const [voteStatus, setVoteStatus] = useState("init")

    useEffect(() => {
        axios.post("/api/vote", {
            id: guid,
            option: option,
            language: language
        })
            .then((response) => {
                console.log(response);
                setVoteStatus("ok")
            })
            .catch(error => {
                setVoteStatus("error")
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.error('Status Code:', error.response.status);
                    console.error('Response Data:', error.response.data);
                    console.error('Response Headers:', error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    console.error('No response received');
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.error('Error:', error.message);
                }

            });

    }, []);

    function body() {
        if (voteStatus === "init")
            return <VoteInProgress {...props}></VoteInProgress>
        else if (voteStatus === "ok")
            return <PollResultsSuccess {...props}></PollResultsSuccess>
        else return <PollResultsInternalError {...props}/>
    }

    return <div className="px-4 py-2 my-5 text-center">{body()}</div>

}

export default PollResults;