import {LanguageContext} from "./context/language";
import {useHistory, useLocation} from "react-router-dom/cjs/react-router-dom";
import {useEffect, useState} from "react";

function LanguageEnforcer(props) {
    const [language, setLanguage] = useState({
        "language": "ro",
        "source": "init"
    });
    let location = useLocation();
    let history = useHistory();
    let params = new URLSearchParams(location.search)

    function changeLangQueryParam(lang) {
        params.set("lang", lang);
        history.replace({
            pathname: location.pathname,
            search: params.toString()
        });
    }

    useEffect(() => {
        let paramsLang = params.get("lang");
        if (language.source === "init") {
            if (paramsLang) {
                if (language.language !== paramsLang) {
                    console.debug("Setting language by queryParam")
                    setLanguage({
                        language: paramsLang,
                        source: "queryParam"
                    });
                }
            } else {
                // changeLangQueryParam(language.language)
            }
        }


        if (language.source === "app" && paramsLang !== language.language) {
            changeLangQueryParam(language.language)
        }

    }, [language, location]);


    return <LanguageContext.Provider value={{
        language: language.language, setLanguage: function (lang) {
            setLanguage({
                language: lang,
                source: "app"
            })
        }
    }}>
        {props.children}
    </LanguageContext.Provider>

}


export default LanguageEnforcer;
