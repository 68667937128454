import {useLanguage} from "./context/language";
import {useState} from "react";
import {useHistory} from "react-router-dom/cjs/react-router-dom";
import {useParams} from "react-router-dom";


const textValues = {
    header: {
        "ro": "Alegeți opțiunea potrivită", "ru": "Выберите подходящий вариант"
    }, options: [
        {
            header: {
                "ro": "Voi utiliza", "ru": "Буду пользоваться"
            }, text: {
                "ro": "dacă integritatea proprietății mele și prețul nu ridică întrebări.",
                "ru": "если сохранность моего имущества и цена не будут вызывать вопросов"
            }
        },
        {
            header: {
                "ro": "Nu am nimic împotrivă", "ru": "Я не против"
            }, text: {
                "ro": "dar în prezent nu am nevoie de așa ceva.", "ru": "но сам в таком на данный момент не нуждаюсь. "
            }
        }, {
            header: {
                "ro": "Sunt împotrivă", "ru": "Я против"
            }, text: {
                "ro": "și am motive.", "ru": "и у меня есть причины"
            }
        }],
    send: {
        ro: "Trimite",
        ru: "Отправить"
    }
}

function Poll(props) {

    const {language} = useLanguage()
    const [option, setOption] = useState(-1)
    const history = useHistory()
    const {guid} = useParams();

    function getStylingForOption(opt) {
        if (option == opt) {
            return "active";
        } else {
            return "";
        }
    }

    function completeVoting() {
        history.push({
            pathname: `/poll/v1/${guid}/vote/${option}`
        });
    }

    return <div className="container mt-3">
        <h1 className="display-6 fw-normal mb-4 text-secondary text-center">{textValues.header[language]}</h1>
        <div className="list-group">
            <a href="#" className={"list-group-item list-group-item-action d-flex gap-3 py-3 " + getStylingForOption(1)}
               aria-current="true" onClick={(e) => setOption((1))}>
                <i className="bi-1-circle-fill rounded-circle fs-2  text-info"></i>
                <div className="d-flex gap-2 w-100 justify-content-between">
                    <div>
                        <h6 className="mb-0">{textValues.options[0].header[language]}</h6>
                        <p className="mb-0 opacity-75">{textValues.options[0].text[language]}</p>
                    </div>
                    <i className="bi-heart-fill rounded-circle fs-6 text-secondary"></i>
                </div>
            </a>
            <a href="#" className={"list-group-item list-group-item-action d-flex gap-3 py-3 " + getStylingForOption(2)}
               aria-current="true" onClick={(e) => setOption((2))}>
                <i className="bi-2-circle-fill rounded-circle fs-2  text-info"></i>
                <div className="d-flex gap-2 w-100 justify-content-between">
                    <div>
                        <h6 className="mb-0">{textValues.options[1].header[language]}</h6>
                        <p className="mb-0 opacity-75">{textValues.options[1].text[language]}</p>
                    </div>
                    <i className="bi-hand-thumbs-up-fill rounded-circle fs-6 text-secondary"></i></div>
            </a>
            <a href="#" className={"list-group-item list-group-item-action d-flex gap-3 py-3 " + getStylingForOption(3)}
               aria-current="true" onClick={(e) => setOption((3))}>
                <i className="bi-3-circle-fill rounded-circle fs-2  text-info"></i>

                <div className="d-flex gap-2 w-100 justify-content-between">
                    <div>
                        <h6 className="mb-0">{textValues.options[2].header[language]}</h6>
                        <p className="mb-0 opacity-75">{textValues.options[2].text[language]}</p>
                    </div>
                    <i className="bi-heartbreak-fill rounded-circle fs-6 text-secondary"></i>
                </div>
            </a>
        </div>
        <div className="col-lg-12 mx-auto">
            <button type="button"
                    className={"btn btn-primary btn-lg mt-2 me-md-2 w-100 " + ((option > 0) ? "" : "disabled")}
                    onClick={e => completeVoting()}><i
                className="bi-send me-3"></i>{textValues.send[language]}
            </button>
        </div>


    </div>
}


export default Poll