import {useLanguage} from "./context/language";
import {useState} from "react";
import {useParams} from "react-router-dom";
import axios from "axios";

const translations = {
    name: {
        "ru" : "Как к вам обращаться?",
        "ro" : "Cum vă numiți?"
    },

    phone: {
        "ro": "Număr de telefon",
        "ru": "Номер телефона"
    },

    phoneComment: {
        "ro": "Dacă nu doriți să fiți sunat, vă rugăm să lăsați în comentarii canalul preferat de comunicare (Telegram, WhatsApp, email, SMS).",
        "ru": "Если вы не хотите, чтобы вам звонили, оставьте в комментарии предпочтительный канал связи (Telegram, WhatsApp, email, SMS)."
    },

    comment: {
        "ru": "Дополнительный комментарий",
        "ro": "Comentariu suplimentar"
    },
    commentSubText: {
        "ru": "Альтернативные способы связи, пожелания, вопросы",
        "ro": "Alternative de contact, sugestii, întrebări"
    },
    send : {
        "ru": "Отправить",
        "ro" : "Trimite"
    }
}


function UploadSuccess(props) {
    const {language} = useLanguage()

    const text = {
        wait: {
            "ru": "Готово!",
            "ro": "Completat!"
        },
        processing: {
            "ru": "Мы очень благодарны за ваш голос и контактные данные.",
            "ro": "Suntem foarte recunoscători pentru votul și datele de contact."
        }
    }

    return <div className="alert alert-success text-center" role="alert">
        <h4 className="alert-heading">{text.wait[language]}</h4>
        <p>{text.processing[language]}</p>
    </div>
}

function UploadInProgress(props) {
    const {language} = useLanguage()

    const text = {
        wait: {
            "ru": "Пожалуйста подождите",
            "ro": "Vă rugăm să așteptați"
        },
        processing: {
            "ru": "Мы записываем ваши данные",
            "ro": "Noi înregistrăm datele dumneavoastră."
        }
    }

    return <div className="alert alert-info text-center" role="alert">
        <h4 className="alert-heading">{text.wait[language]}</h4>
        <p>{text.processing[language]}</p>
    </div>
}

function UploadResultsInternalError(props) {
    const {language} = useLanguage()

    const text = {
        wait: {
            "ru": "Произошла техническая ошибка",
            "ro": "A apărut o eroare tehnică."
        },
        processing: {
            "ru": "Пожалуйста, попробуйте перегрузить страницу. Если это не помогло, убедительная просьба попробовать чуть позже - ваш голос очень важен для нас и было бы досадно его потерять. ",
            "ro": "Vă rugăm să încercați să reîmprospătați pagina. Dacă asta nu ajută, vă rugăm să încercați mai târziu - votul dumneavoastră este foarte important pentru noi și ar fi păcat să-l pierdem. Mulțumim!"
        }
    }

    return <div className="alert alert-danger text-center" role="alert">
        <h4 className="alert-heading">{text.wait[language]}</h4>
        <p>{text.processing[language]}</p>
        <hr/>

    </div>
}

function Contacts(props) {
    const {language} = useLanguage()
    const [name, setName] = useState("")
    const [phone, setPhone] = useState("")
    const [comment, setComment] = useState("")
    const {guid} = useParams();


    const [formStatus, setFormStatus] = useState("input")

    const onSubmit = () => {
        let json = {name, phone, comment, id: guid}
        setFormStatus("progress")
        axios.post("/api/addcontacts", json)
            .then((response) => {
                console.log(response);
                setFormStatus("success")
            })
            .catch(error => {
                setFormStatus("error")
                console.error(error)
            });
        console.log(json)
    }

    const onChange = (setValue) => e => setValue(e.target.value)

    function body() {
        if (formStatus === "input") {
            return <div className="">
                <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                        <div>{translations.name[language]}</div>
                    </label>
                    <input type="text" className="form-control" id="name" name="name" value={name}
                           onChange={onChange(setName)} required/>
                </div>
                <div className="mb-3">
                    <label htmlFor="phone" className="form-label">
                        <div>{translations.phone[language]}</div>
                        <small className="form-text text-muted">{translations.phoneComment[language]}</small>

                    </label>
                    <input type="tel" className="form-control" id="phone" name="phone" value={phone}
                           onChange={onChange(setPhone)}/>
                </div>


                <div className="mb-3">
                    <label htmlFor="comment" className="form-label">
                        <div>{translations.comment[language]}</div>
                        <small className="form-text text-muted">{translations.commentSubText[language]}</small>
                    </label>
                    <textarea className="form-control" id="comment" name="comment" rows="3" value={comment}
                              onChange={onChange(setComment)}></textarea>
                </div>
                <button type="submit" className="btn btn-primary w-100" onClick={e => onSubmit()}><i
                    className="bi-send-check-fill me-3"/>{translations.send[language]}</button>
            </div>
        } else if (formStatus === "error") {
            return <UploadResultsInternalError {...props}/>
        } else if (formStatus === "success") {
            return <UploadSuccess  {...props}/>
        } else if (formStatus === "progress") {
            return <UploadInProgress  {...props}/>
        }
    }

    return <div className="px-4 py-2 my-5 container mt-5">{body()}</div>


}

export default Contacts