import React, {useEffect, useState} from "react";
import chef from './img/chef-hat.png'
import cart from './img/shopping-cart.png'
import art from './img/art.svg'
import Navbar from "./Navbar";

import ScrollSpy from 'react-scrollspy-navigation'

import img_buffalo_wings from "./img/buffalo-wings.png";
import img_gochujang_wings from "./img/gochujang-wings.png";
import img_bbq_wings from "./img/bbq-wings.png";
import img_honey_mustard_wings from "./img/honey-mustard-wings.png";
import img_vietnamese_wings from "./img/vietnameese-wings.png";

import img_cauliflower_bbq from "./img/bbq-cauliflower.png";
import img_cauliflower_buffalo from "./img/buffalo-cauliflower.png";
import img_cauliflower_honeymustard from "./img/honey-mustard-cauliflower.png";
import img_cauliflower_gochujang from "./img/gochujang-cauliflower.png";


import img_potato_fries from "./img/potato-fries.png";
import img_potato_wedges from "./img/potato-wedges.png";
import img_potato_balls from "./img/potato-balls.png";

import img_beer_harap from "./img/beer-harap-alb.png";
import img_beer_110 from "./img/beer-110-ale.png";
import img_beer_goldenlager from "./img/beer-golden-lager.png";
import img_beer_darklager from "./img/beer-dark-lager.png";

import img_kvas from "./img/kvas.png";
import img_nestea from "./img/nestea.png";
import img_omion_rings from "./img/img_onion_rings.png";


import {useLanguage} from "./context/language";


const prices = {
    wings: "12 buc - 160MDL / 8 buc - 110MDL",
    cauliflower:"12 buc - 160MDL / 8 buc -110MDL",
    potato_fries: "50 MDL",
    potato_wedges_and_balls: "60 MDL",
    beer_lager: "45 MDL",
    beer_others: "50 MDL",
    kvas: "40 MDL",
    onion_rings: "65 MDL",
    nestea: "40 MDL"
}

function MenuItemOptions(props) {
    const { options, choices, changed, onChoiceChange} = props;
    const {language} = useLanguage()
    const [firstRender, setFirstRender] = useState(true)
    useEffect(() => {
        const timeout =setTimeout(() => {
            setFirstRender(false);
        }, 600);

        return () => {
            clearTimeout(timeout);
        };
    }, [firstRender]);

    const change = (position) => {
        const newChoices = [...choices]
        console.log(JSON.stringify(options))
        newChoices[position] = (choices[position] === options[position].options.length -1 ) ? 0 : choices[position] + 1;
        setFirstRender(true);
        onChoiceChange(newChoices);
    }
    return <ul className="list-group list-group-flush">
        {
            options.map((option, index) => {
                    const style = (index === changed && firstRender) ? {"backgroundColor":'#34eb7a', transition: "all .3s ease"} : {"backgroundColor":'white', transition: "all .3s ease"}
                    return (<li  className="list-group-item d-flex justify-content-between align-items-center" style={style}>
                        <small>{option.options[choices[index]][language]} {option.options[choices[index]]["visual"]} </small>
                        <i className="bi-pencil-square" onClick={ e =>
                            change(index)
                        }/>
                    </li>)
                }
            )
        }
    </ul>
}

const potatoOptions = [
   {
        "ro": "Sos de înmuiat",
        "ru": "Макательный соус",
        "en": "Dipping sauce",
        "options": [
            {
                "ru": "Майонез",
                "ro": "Maioneza",
                "en": "Mayo"
            },
            {
                "ru": "Кетчуп",
                "ro": "Ketchup",
                "en": "Ketchup"
            }
        ]
    },
]

const allSpicyOptions =  {
    "ro": "Grad de iuțeală",
    "ru": "Уровень остроты",
    "en": "Spiciness level",
    "options": [
        {
            "ro": "Ușor picant",
            "ru": "Пикантный",
            "en": "Mildly spicy",
            "visual": <>
                <i className="bi-fire" style={{color: 'red'}}/> <i className="bi-fire"/> <i className="bi-fire"/>
            </>
        },
        {
            "ro": "Picant moderat",
            "ru": "Средняя острота",
            "en": "Moderately spicy",
            "visual": <>
                <i className="bi-fire" style={{color: 'red'}}/> <i className="bi-fire" style={{color: 'red'}}/> <i className="bi-fire"/>
            </>
        },
        {
            "ro": "Foarte picant",
            "ru": "Очень острый",
            "en": "Very spicy",
            "visual": <>
                <i className="bi-fire" style={{color: 'red'}}/> <i className="bi-fire" style={{color: 'red'}}/> <i
                className="bi-fire" style={{color: 'red'}}/>
            </>
        },
        {
            "ro": "Extrem de iute",
            "ru": "Экстремально острый",
            "en": "Extremely spicy",
            "visual": <>
                <i className="bi-fire" style={{color: 'red'}}/> <i className="bi-fire" style={{color: 'red'}}/> <i
                className="bi-fire" style={{color: 'red'}}/> <i
                className="bi-fire" style={{color: 'red'}}/>
            </>
        }
    ]
}
const wingOptions = [
    {
        "ro": "Sos de înmuiat",
        "ru": "Макательный соус",
        "en": "Dipping sauce",
        "options": [
            {
                "ru": "Соус из голубого сыра",
                "ro": "Sos blue cheese",
                "en": "Blue cheese sauce"
            },
            {
                "ru": "Соус \"Рэнч\"",
                "ro": "Sos \"Ranch\"",
                "en": "Ranch dressing"
            }
        ]
    },
    {
        "ro": "Legume garnitură",
        "ru": "Овощи в прикуску",
        "en": "Side vegetables",
        "options": [
            {
                "ru": "Мокровка и сельдерей",
                "ro": "Telină si morcov",
                "en": "Celery and carrots"
            },
            {
                "ru": "Только морковка",
                "ro": "Doar morcov",
                "en": "Just carrots"
            },
            {
                "ru": "Только сельдерей",
                "ro": "Doar telină",
                "en": "Just celery"
            }
        ]
    },
]


const menuWings = [
    {
        "img": img_buffalo_wings,
        "category": "wings",
        "price": prices.wings,
        "name": {
            "ru" : <>Крылышки баффало<i className="bi-fire" style={{color: 'red'}}/></>,
            "ro": <>Aripioare Buffalo<i className="bi-fire" style={{color: 'red'}}/></>,
            "en": <>"Buffalo wings"<i className="bi-fire" style={{color: 'red'}}/></>
        },
        "description": {
            "ru" : "Хрустящие куриные крылышки в остром соусе с выраженным уксусным вкусом, подаются с соусом блю чиз или ранч, а также с сельдереем и морковью.",
            "ro": "Aripioare crocante de pui, glazurate cu un sos picant, aromat și cu o notă puternică de oțet, servite alături de dressing de brânză albastră sau ranch, cu garnitură de țelină și morcovi.",
            "en": "Crispy chicken wings coated in a tangy, spicy sauce with a strong vinegar flavor, served with blue cheese or ranch dressing, accompanied by celery and carrot sticks."
        },
        options: wingOptions
    },{
        "img": img_gochujang_wings,
        "price": prices.wings,
        "category": "wings",
        "name": {
            "ru" : <>Крылышки в соусе Гочуджан<i className="bi-fire" style={{color: 'red'}}/></>,
            "ro": <>"ripioare Coreene cu pasta Gochujang<i className="bi-fire" style={{color: 'red'}}/></>,
            "en": <>Gochujang Korean Wings<i className="bi-fire" style={{color: 'red'}}/></>
        },
        "description": {
            "ru" : "Хрустящие куриные крылышки, покрытые сладко-острым соусом на основе корейской пасты Гочуджан, подаются с палочками сельдерея и моркови для свежего акцента.",
            "ro": "Aripioare crocante de pui, glazurate cu un sos dulce-picant pe bază de pastă coreeană Gochujang, servite alături de bețe de țelină și morcovi pentru un contrast răcoritor.",
            "en": "Crispy chicken wings coated in a sweet and spicy sauce made with Korean Gochujang paste, served with celery and carrot sticks for a refreshing balance."
        },
        options: wingOptions
    },
    {
        "img": img_honey_mustard_wings,
        "price": prices.wings,
        "category": "wings",
        "name": {
            "ru" : "Крылышки с медом и горчицей",
            "ro": "Aripioare cu Miere și Muștar",
            "en": "Honey Mustard Wings"
        },
        "description": {
            "ru" : "Хрустящие куриные крылышки, покрытые сладким и нежным соусом из меда и горчицы, подаются с палочками сельдерея и моркови для свежести.",
            "ro": "Aripioare de pui crocante, glazurate cu un sos dulce și cremos pe bază de miere și muștar, servite cu bețe de țelină și morcovi pentru un contrast răcoritor.",
            "en": "Crispy chicken wings coated in a sweet and creamy honey mustard sauce, served with celery and carrot sticks for a refreshing balance."
        },
        options: wingOptions
    },
    {
        "img": img_vietnamese_wings,
        "price": prices.wings,
        "category": "wings",
        "name": {
            "ru" : "Крылышки с вьетнамским рыбным соусом",
            "ro": "Aripioare cu Sos Vietnamez de Pește",
            "en": "Vietnamese Fish Sauce Wings"
        },
        "description": {
            "ru" : "Хрустящие куриные крылышки, маринованные в ароматном вьетнамском соусе на основе рыбного соуса, лайма и сахара, подаются с сельдереем и морковью..",
            "ro": "Aripioare crocante de pui, marinate într-un sos vietnamez aromat pe bază de sos de pește, lime și zahăr, servite cu țelină și morcovi pentru prospețime.",
            "en": "Crispy chicken wings marinated in a flavorful Vietnamese fish sauce blend with lime and sugar, served with celery and carrot sticks for a fresh touch."
        },
        options: wingOptions
    },
    {
        "img": img_bbq_wings,
        "price": prices.wings,
        "category": "wings",
        "name": {
            "ru": "Крылышки с BBQ соусом",
            "ro": "Aripioare cu Sos BBQ",
            "en": "BBQ Wings"
        },
        "description": {
            "ru": "Сочные куриные крылышки в насыщенном копченом BBQ соусе, дополненные палочками сельдерея и моркови.",
            "ro": "Aripioare fragede de pui, acoperite cu un sos BBQ afumat și bogat, acompaniate de bețe de țelină și morcovi.",
            "en": "Tender chicken wings smothered in a rich, smoky BBQ sauce, accompanied by celery and carrot sticks."
        },
        "options": wingOptions
    }

]

const menuCauliflowr = [
    {
        "img": img_cauliflower_buffalo,
        "category": "cauliflower",
        "price": prices.cauliflower,

        "name": {
            "ru": <>Цветная капуста Баффало<i className="bi-fire" style={{color: 'red'}}/></>,
            "ro": <>Conopidă Buffalo<i className="bi-fire" style={{color: 'red'}}/></>,
            "en": <>>Buffalo Cauliflower<i className="bi-fire" style={{color: 'red'}}/></>
        },
        "description": {
            "ru": "Хрустящие кусочки жареной цветной капусты в остром соусе с выраженным уксусным вкусом, подаются с соусом блю чиз или ранч, а также с сельдереем и морковью.",
            "ro": "Bucăți de conopidă crocantă, prăjite și glazurate cu un sos picant și aromat, cu o notă puternică de oțet, servite alături de dressing de brânză albastră sau ranch, și garnitură de țelină și morcovi.",
            "en": "Crispy fried cauliflower florets coated in a tangy, spicy Buffalo sauce with a strong vinegar flavor, served with blue cheese or ranch dressing and a side of celery and carrot sticks."
        },
        "options": wingOptions
    },
    {
        "img": img_cauliflower_gochujang,
        "category": "cauliflower",
        "price": prices.cauliflower,

        "name": {
            "ru": <>Цветная капуста в соусе Гочуджан<i className="bi-fire" style={{color: 'red'}}/></>,
            "ro": <>Conopidă Coreeană cu Gochujang<i className="bi-fire" style={{color: 'red'}}/></>,
            "en": <>>Gochujang Korean Cauliflower<i className="bi-fire" style={{color: 'red'}}/></>
        },
        "description": {
            "ru": "Обжаренная цветная капуста в сладко-остром соусе из корейской пасты Гочуджан, подается с сельдереем и морковью.",
            "ro": "Conopidă crocantă, acoperită cu un sos dulce-picant pe bază de pastă coreeană Gochujang, servită cu bețe de țelină și morcovi pentru un contrast răcoritor.",
            "en": "Crispy cauliflower coated in a sweet and spicy sauce made with Korean Gochujang paste, served with celery and carrot sticks for a refreshing balance."
        },
        "options": wingOptions
    },
    {
        "img": img_cauliflower_honeymustard,
        "category": "cauliflower",
        "price": prices.cauliflower,

        "name": {
            "ru": "Цветная капуста с медом и горчицей",
            "ro": "Conopidă cu Miere și Muștar",
            "en": "Honey Mustard Cauliflower"
        },
        "description": {
            "ru": "Хрустящие кусочки жареной цветной капусты в сладком и нежном медово-горчичном соусе, дополненные палочками сельдерея и моркови.",
            "ro": "Bucăți de conopidă prăjită, glazurate cu un sos dulce și cremos de miere și muștar, acompaniate de țelină și morcovi.",
            "en": "Deep-fried cauliflower florets glazed in a sweet and creamy honey mustard sauce, accompanied by celery and carrot sticks."
        },
        "options": wingOptions
    },

    {
        "img": img_cauliflower_bbq,
        "category": "cauliflower",
        "price": prices.cauliflower,

        "name": {
            "ru": "Цветная капуста с BBQ соусом",
            "ro": "Conopidă cu Sos BBQ",
            "en": "BBQ Cauliflower"
        },
        "description": {
            "ru": "Хрустящие кусочки цветной капусты, покрытые насыщенным копченым BBQ соусом, подаются с сельдереем и морковью.",
            "ro": "Flori de conopidă crocantă, acoperite cu un sos BBQ afumat și bogat, servite cu țelină și morcovi.",
            "en": "Crispy cauliflower florets smothered in a rich, smoky BBQ sauce, served with celery and carrot sticks."
        },
        "options": wingOptions
    }

]

const menuPotato = [
    {
        "img": img_potato_fries,
        "category": "beer_snacks",
        "price": prices.potato_fries,
        "name": {
            "ru": "Картофель фри",
            "ro": "Cartofi prăjiți",
            "en": "Fries"
        },
        "description": {
            "ru": "Золотистый картофель фри, хрустящий снаружи и мягкий внутри, подается с кетчупом и майонезом на выбор.",
            "ro": "Cartofi prăjiți aurii, crocanți la exterior și moi la interior, serviți cu ketchup sau maioneză la alegere.",
            "en": "Golden fries, crispy on the outside and soft on the inside, served with your choice of ketchup or mayo."
        },
        "options": potatoOptions
    }
,
    {
        "img": img_potato_wedges,
        "category": "beer_snacks",
        "price": prices.potato_wedges_and_balls,

        "name": {
            "ru": "Жареные картофельные дольки",
            "ro": "Cartofi wedges prăjiți",
            "en": "Deep-Fried Potato Wedges"
        },
        "description": {
            "ru": "Хрустящие жареные картофельные дольки, приправленные специями, подаются с кетчупом и майонезом на выбор.",
            "ro": "Cartofi wedges crocanți, prăjiți și asezonați cu condimente, serviți cu ketchup sau maioneză la alegere.",
            "en": "Crispy deep-fried potato wedges, seasoned with spices, served with your choice of ketchup or mayo."
        },
        "options": potatoOptions
    }
,
    {
        "img": img_potato_balls,
        "price": prices.potato_wedges_and_balls,
        "category": "beer_snacks",
        "name": {
            "ru": "Жареные картофельные шарики",
            "ro": "Bile de cartofi prăjite",
            "en": "Deep-Fried Potato Balls"
        },
        "description": {
            "ru": "Золотистые картофельные шарики с хрустящей корочкой, подаются с кетчупом и майонезом на выбор.",
            "ro": "Biluțe de cartofi prăjite, aurii și crocante, servite cu ketchup sau maioneză la alegere.",
            "en": "Golden deep-fried potato balls with a crispy coating, served with your choice of ketchup or mayo."
        },
        "options": potatoOptions
    },
    {
        "img": img_omion_rings,
        "price": prices.onion_rings,
        "category": "beer_snacks",
        "name": {
            "ru": "Луковые кольцы",
            "ro": "Inele de ceapă",
            "en": "Onion rings"
        },
        "description": {
            "ru": "",
            "ro": "",
            "en": ""
        },
        "options": potatoOptions
    }

]

const nesteaOptions = [
    {
        "ro": "Volum",
        "ru": "Объем",
        "en": "Volume",
        "options": [
            {
                "ru": "500 ml",
                "ro": "500 ml",
                "en": "500 ml"
            }
        ]
    },
    {
        "ro": "Gust",
        "ru": "Вкус",
        "en": "Type",
        "options": [
            {
                "ru": "Лесные фрукты",
                "ro": "Fructe de padure",
                "en": "Forest fruits"
            },
            {
                "ru": "Персик",
                "ro": "Piersic",
                "en": "Peach"
            },
            {
                "ru": "Лимон",
                "ro": "Lamaie",
                "en": "Lemon"
            },
            {
                "ru": "Груша и личи",
                "ro": "Prasada si litchi",
                "en": "Pear Lychee"
            }
        ]
    },
]
const beerOptions = [
    {
        "ro": "Volum",
        "ru": "Объем",
        "en": "Volume",
        "options": [
            {
                "ru": "500 ml",
                "ro": "500 ml",
                "en": "500 ml"
            }
        ]
    },
]


const menuBeer = [
    {
        "img": img_beer_harap,
        "category": "beer",
        "price": prices.beer_others,
        "name": {
            "ru": "Beermaster Harap Alb",
            "ro": "Beermaster Harap Alb",
            "en": "Beermaster Harap Alb"
        },
        "description": {
            "ru": "Бланш появился в Бельгии 400 лет назад. Изначально, его варили монахи, которые довели рецептуру до совершенства. ",
            "ro": "Bière blanche, cunoscută și sub numele de witbier (bere albă), își are originea în Belgia cu aproximativ 400 de ani în urmă.",
            "en": "Blanche beer, also known as witbier (white beer), originated in Belgium around 400 years ago."
        },
        "options": beerOptions
    }
    ,
    {
        "img": img_beer_110,
        "category": "beer",
        "price": prices.beer_others,
        "name": {
            "ru": "Beermaster 110 ale",
            "ro": "Beermaster 110 ale",
            "en": "Beermaster 110 ale"
        },
        "description": {
            "ru": "Все гениальное просто! СОЛОД и ХМЕЛЬ – два основных и натуральных ингредиента.",
            "ro": "MALȚ și HAMEI – două ingrediente pure, esențiale.",
            "en": "All ingenious is simple! MALT and HOP are two main natural ingredients. "
        },
        "options": beerOptions
    }
    ,
    {
        "img": img_beer_goldenlager,
        "category": "beer",
        "price": prices.beer_lager,
        "name": {
            "ru": "Beermaster Golden Lager",
            "ro": "Beermaster Golden Lager",
            "en": "Beermaster Golden Lager"
        },
        "description": {
            "ru": "Это светлое, непастеризованное, благородное, мягкое и свежее одновременно пиво с тонким ароматом хмеля. ",
            "ro": "Este o bere blondă, nepasteurizată cu aromă fină de hamei aromat, nobil, moale și proaspătă în același timp. ",
            "en": "It is a blond beer, unpasteurized with a fine aroma of hops, aromatic, noble, soft and fresh at the same time."
        },
        "options": beerOptions
    },
    {
        "img": img_beer_darklager,
        "category": "beer",
        "price": prices.beer_lager,
        "name": {
            "ru": "Beermaster Dark Lager",
            "ro": "Beermaster Dark Lager",
            "en": "Beermaster Dark Lager"
        },
        "description": {
            "ru": "Получает свой темно–золотистый цвет, а также его полноту вкуса в процессе производства пива из высококачественного карамельного солода.",
            "ro": "este o bere brună cu corp plin și puternic, avînd spumă densă și mătăsoasă ce își capătă culoarea brună-rubinie, precum și plinătatea gustului din malțul caramel atent selectat de cea mai înaltă calitate",
            "en": "Soft lager with hints of roastiness. The dark malts come across more as chocolate and caramel." +
                "It has wonderful balance and strong dunkel character."
        },
        "options": beerOptions
    }
    ,
    {
        "img": img_kvas,
        "category": "beer",
        "price": prices.kvas,
        "name": {
            "ru": "Beermaster Квас",
            "ro": "Beermaster Classic Kvass",
            "en": "Beermaster Classic Kvass"
        },
        "description": {
            "ru": "Хлебный квас производится по специальному традиционному рецепту, который придает напитку настоящий вкус хлеба.",
            "ro": "Cvasul Hlebnîi este produs după o recetă tradițională specială ce îi conferă băuturii un gust de pâine adevărat.",
            "en": "Bread Kvass is produced upon the special traditional recipe that adds a true taste of bread to the beverage."
        },
        "options": beerOptions
    }
]
const menuNonAlco =  [{
    "img": img_nestea,
    "category": "soft_drinks",
    "price": prices.nestea,
    "name": {
        "ru": "Чай Nestea",
        "ro": "Ceai Nestea",
        "en": "Nestea"
    },
    "description": {
        "ru": "Чай Nestea в ассортименте ",
        "ro": "Ceai Nestea in asortiment",
        "en": "Nestea beverage, multiple options"
    },
    "options": nesteaOptions
}];


const menu = [
    ...menuWings,
    ...menuCauliflowr,
    ...menuPotato,
    ...menuBeer,
    ...menuNonAlco
]

function MenuItem(props) {
    const {item} = props;

    const {language} = useLanguage();
    const i18nAdd = {
        "ru": "Добавить",
        "ro": "Adauga",
        "en": "Add"
    };

    const [choices, setChoices] = useState({
        changed: -1,
        choices: new Array(item.options.length).fill(0)
    });

    function updateChoices(updatedChoices) {
        for (let i = 0; i < choices.choices.length; i++) {
            if (choices.choices[i] !== updatedChoices[i]) {
                setChoices({
                    changed: i,
                    choices: updatedChoices
                });
                break;
            }
        }
    }


    return <div className="card border-dark bg-light p-2 mt-3">
        <img src={item.img} className="card-img-top" alt="..."/>
        <div className="card-body">
            <h5 className="card-title">{item.name[language]}</h5>
            <h6 className="card-subtitle">{item.price}</h6>

            <p className="card-text mt-2"><small className="text-muted">{item.description[language]}</small></p>
        </div>
        <MenuItemOptions options={item.options} choices={choices.choices} changed={choices.changed}
                         onChoiceChange={updateChoices}/>
        <div className="card-body">
            <a href="#" className="btn btn-success">{i18nAdd[language]}</a>
        </div>
    </div>
}

const categories = [
    {
        "id": "wings",
        "name": {
            "ru" : "Крылышки",
            "ro": "Aripiore",
            "en": "Wings"
        }
    },
    {
        "id": "cauliflower",
        "name": {
            "ru" : "Капуста",
            "ro":  "Conopidă",
            "en": "Cauliflower"
        }
    },
    {
        "id": "beer_snacks",
        "name": {
            "ru" : "Фритюр",
            "ro":  "Friteuza",
            "en": "Fries"
        }
    },
    {
        "id": "beer",
        "name": {
            "ru" : "Пиво",
            "ro":  "Bere",
            "en": "Beer"
        }
    },
    {
        "id": "soft_drinks",
        "name": {
            "ru" : "Безалкогольные напитки",
            "ro":  "B-ri fara alcool",
            "en": "Non-alcoholic drinks"
        }
    }
]

function RestaurantMenu(props) {
    const {language} = useLanguage()
    const byCategory = categories.map((category) => {
        return {
            "menuItems": menu.filter((menuItem) => menuItem.category === category.id),
            "category": category.id
        }
    })


    return (
        <div>
            <ScrollSpy activeClass="btn-dark" >

            <nav className="sticky-top bg-light border-bottom pt-3 pb-3">
                    <div className="container-fluid">
                        {
                            categories.map((cat) => {
                                return (
                                    <a type="button" href={"#" + cat.id} className="btn btn-outline-secondary">{cat.name[language]}</a>
                                );
                            })
                        }
                    </div>
            </nav>
            </ScrollSpy>

            <div className="bg-white">
                <div className="container pt-2">
                    {
                        byCategory.map((category, index) => {
                            return <section id={category.category} key={category}>
                                {
                                    category.menuItems.map((menuItem, index) => {
                                            return (<MenuItem item={menuItem}/>)
                                        }
                                    )
                                }
                            </section>
                            }
                        )
                    }


                </div>
            </div>
        </div>
    )
}

export default RestaurantMenu;