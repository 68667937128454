import {useLanguage} from "./context/language";
import {useState} from "react";
import {useParams} from "react-router-dom";
import axios from "axios";
import {useHistory} from "react-router-dom/cjs/react-router-dom";

const translations = {
    enterData: {
        "ru": "Пожалуйста введите уникальный код",
        "ro": "Vă rugăm să introduceți codul unic."
    },
}


function Manual(props) {
    const {language} = useLanguage()
    const [code, setCode] = useState("")

    let history = useHistory();

    function onSubmit() {

        history.push({
            pathname: `/poll/v1/${code.toUpperCase()}`
        });

    }

    const onChange = (setValue) => e => setValue(e.target.value)

    function body() {
        return <div className="">
            <div className="mb-3">
                <label htmlFor="uid" className="form-label">
                    <div>{translations.enterData[language]}</div>
                </label>
                <input type="text" className="form-control" id="name" name="name" value={code.toUpperCase()}
                       onChange={onChange(setCode)} required/>
            </div>
            <button type="submit" className={"btn btn-primary w-100 " + ((code.length < 6) ? "disabled" : "")}
                    onClick={e => onSubmit()}>
                <i className="bi-send-check-fill me-3"/>OK
            </button>
        </div>

    }

    return <div className="px-4 py-2 my-5 container mt-5">{body()}</div>
}

export default Manual