import React from "react";
import chef from './img/chef-hat.png'
import cart from './img/shopping-cart.png'
import art from './img/art.svg'
import Navbar from "./Navbar";
import {useHistory} from "react-router-dom/cjs/react-router-dom";
import {Link} from "react-router-dom";
import logo from "./img/logo.png";
import {useLanguage} from "./context/language";

const i18nHomePage = {
    menu: {
        ru: "Еда и напитки",
        ro: "Gustări și băuturi",
        en: "Food and drinks"
    },
    menuDescription: {
        ru: "Закажите, не дожидаясь официанта. ",
        ro: "Comandați fără să așteptați chelnerul. ",
        en: "Order without waiting for the waiter."
    },

    openMenuButton: {
      ru: "Перейти в меню",
      ro: "Accesați meniul",
      en: "Go to the menu"
    },
    myTable: {
        ru: "Мой столик",
        ro: "Masa mea",
        en: "My table"
    },
    myTableDescription: {
        ru: "Статус вашего заказа, попросить счёт",
        ro: "Starea comenzii, cereți nota de plată.",
        en: "Order status, request the bill."
    },

    myTableYouDidntOrderAnything: {
        ru: "Вы еще ничего не заказали",
        ro: "Nu ați comandat încă nimic.",
        en: "You haven't ordered anything yet."
    },

    pictures: {
        ru: "Картины",
        ro: "Picturi",
        en: "Paintings"
    },
    picturesDescription: {
        ru: "Вам понравились картины в зале? Они продаются!",
        ro: "V-au plăcut picturile din sală? Sunt de vânzare! ",
        en: "Did you like the paintings? They are for sale!"
    },
    learnMore: {
        ru: "Узнать больше",
        ro: "Aflați mai multe.",
        en: "Find out more."
    }
}
function Home(props) {

    const history = useHistory()
    const {language} = useLanguage()
    return (

        <div className="container mt-2">
            <Navbar/>
            <div className="card bg-light">
                <img className="d-block mx-auto mb-1 mt-2" src={chef} height="100px" alt="Card image cap"/>
                <div className="card-body text-center ">
                    <h4 className="card-title">{i18nHomePage.menu[language]}</h4>
                    <p className="card-text">{i18nHomePage.menuDescription[language]}</p>
                    <Link className="btn btn-outline-primary" to="/menu">
                        {i18nHomePage.openMenuButton[language]}
                    </Link>
                </div>
            </div>

            <div className="card bg-light  mt-2">
                <img className="d-block mx-auto mb-1 mt-2" src={cart} height="100px" alt="Card image cap"/>
                <div className="card-body text-center ">
                    <h4 className="card-title">{i18nHomePage.myTable[language]}</h4>
                    <p className="card-text">{i18nHomePage.myTableDescription[language]}</p>
                    <a href="#" className="btn btn-outline-secondary">{i18nHomePage.myTableYouDidntOrderAnything[language]}</a>
                </div>
            </div>

            <div className="card bg-light  mt-2">
                <img className="d-block mx-auto mb-1 mt-2" src={art} height="100px" alt="Card image cap"/>
                <div className="card-body text-center ">
                    <h4 className="card-title">{i18nHomePage.pictures[language]}</h4>
                    <p className="card-text">{i18nHomePage.picturesDescription[language]}</p>
                    <a href="#" className="btn btn-outline-primary">{i18nHomePage.learnMore[language]}</a>
                </div>
            </div>
        </div>

    )
}

export default Home;