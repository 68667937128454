import React from "react";
import {BrowserRouter as Router, Route} from "react-router-dom";

import './index.css';
import Navbar from './Navbar';
import PollResults from "./PollResults";
import Poll from "./Poll";
import Home from "./Home";
import LanguageEnforcer from "./LanguageEnforcer";
import {v4 as uuidv4} from 'uuid';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import {Redirect} from "react-router-dom"
import Contacts from "./Contacts";
import Manual from "./Manual"
import RestaurantMenu from "./RestaurantMenu"

function App(props) {

    const origin = "";

    return (
        <Router>
            <LanguageEnforcer>
                <Route exact path="/" component={Home}></Route>
                <Route exact path="/menu" component={RestaurantMenu}></Route>

                <Route exact path="/manual/v1/" component={Manual}></Route>
                <Route exact path={origin + "/poll/v1/:guid/contacts"} component={Contacts}/>
                <Route exact path={origin + "/poll/v1"}> <Redirect to={"/manual/v1/"}/></Route>
                <Route exact path={origin + "/poll/v1/:guid"} component={Poll}/>
                <Route exact path={origin + "/poll/v1/:guid/vote/:option"} component={PollResults}/>
            </LanguageEnforcer>
        </Router>
    );
}

export default App;
